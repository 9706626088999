import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Container, Row, Col, Button, Form, Image, NavDropdown, Navbar, Nav } from "react-bootstrap";
import "./VideoUpload.css";

const ThankYou = (props) => {


    return (
        <>
            <div className="thank-you-page-sec">
                <Image
                    className="thank-you-image"
                    src={
                        window.location.origin + "/assets/img/login/thank-you.png"
                    }
                />
                <div className="back-to-home">
                    <Link to="/login-modal" className="back-to-home-btn">
                        Back To Home
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ThankYou;
