export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";


export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";


//video actions

export const FETCH_VIDEO_START = "FETCH_VIDEO_START"
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS"
export const FETCH_VIDEO_FAILURE = "FETCH_VIDEO_FAILURE"

export const SAVE_VIDEO_START = "SAVE_VIDEO_START"
export const SAVE_VIDEO_SUCCESS = "SAVE_VIDEO_SUCCESS"
export const SAVE_VIDEO_FAILURE = "SAVE_VIDEO_FAILURE"