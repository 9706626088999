import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Form,
  Image,
  NavDropdown,
  Navbar,
  Nav,
  Spinner,
} from "react-bootstrap";
import "./VideoUpload.css";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { authContext } from "../account/auth/AuthProvider";
import { useWeb3React } from "@web3-react/core";
import { fetchVideosStart } from "../store/actions/VideosAction";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";

const VideoViewIndex = (props) => {
  const { userProfile, dispatch, video } = props;

  const { hanldeLogout, auth, supportedChains } = useContext(authContext);

  const [playerStatus, setPlayerStatus] = useState({
    buffering: false,
  });

  const context = useWeb3React();
  const { chainId } = context;

  useEffect(() => {
    dispatch(fetchVideosStart());
  }, []);

  const handleVideoEnd = () => {
    setPlayerStatus({
      ...playerStatus,
      buffering: true,
    });
    setTimeout(() => {
      dispatch(fetchVideosStart());
    }, 1000)
  };

  const handleVideoPlay = () => {
    setPlayerStatus({
      ...playerStatus,
      buffering: false,
    });
  };

  const handleVideoBuffer = () => {
    setPlayerStatus({
      ...playerStatus,
      buffering: true,
    });
  };

  const UserMenu = (
    <div className="user-header-profile-img-sec">
      <Image
        src={userProfile.data.picture}
        alt="UserName profile image"
        roundedCircle
      />
      <span>
        {Number(auth.ethBalance).toLocaleString(undefined, {
          maximumFractionDigits: 4,
        })}{" "}
        {
          supportedChains.find((chains) => chains.chainId.includes(chainId))
            ?.symbol
        }
      </span>
    </div>
  );

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    const notificationMessage = getSuccessNotificationMessage(
      "Wallet address copied!"
    );
  };

  return (
    <>
      <div className="video-view-sec">
        <div className="video-view-header-sec">
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="#">
                <Image
                  className="header-logo"
                  src={
                    window.location.origin +
                    "/assets/img/login/meta-view-black.png"
                  }
                />
                <span>
                  <Image
                    className="header-play-icon"
                    src={
                      window.location.origin +
                      "/assets/img/login/play-icon-bg.png"
                    }
                  />
                </span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll">
                <Image
                  className="toggle-menu-img"
                  src={
                    window.location.origin + "/assets/img/login/toggle-menu.png"
                  }
                />
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="ms-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <NavDropdown
                    title={UserMenu}
                    id="navbarScrollingDropdown"
                    className="user-dropdown-menu"
                  >
                    <div className="user-dropdown-menu-header">
                      <Image
                        className="user-dropdown-menu-logo"
                        src={userProfile.data.picture}
                      />
                      <Button className="connected-btn">Connected</Button>
                      <div className="logout-btn-sec">
                        <Button variant="text" onClick={hanldeLogout}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="#FF185B"
                            class="bi bi-box-arrow-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                    <div className="user-dropdown-menu-body">
                      <div className="user-chain-logo-sec">
                        <Image
                          className="etherum-logo-img"
                          src={
                            window.location.origin +
                            "/assets/img/login/binance-logo.svg"
                          }
                        />
                      </div>
                      <div className="user-amount-etherum">
                        <h4>
                          {Number(auth.ethBalance).toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                          })}{" "}
                          {
                            supportedChains.find((chains) =>
                              chains.chainId.includes(chainId)
                            )?.symbol
                          }
                        </h4>
                      </div>
                      {/* <div className="user-amount-balance">
                        <h6>Balance</h6>
                        <h5>$25.989K</h5>
                      </div> */}
                      <div className="user-wallet-id-sec">
                        <p className="wallet-id">
                          {auth.accounts.substr(0, 5)+"..."+
                                auth.accounts.substr(auth.accounts.length - 4)}
                        </p>
                        <span>
                          <Image
                            className="copy-icon"
                            src={
                              window.location.origin +
                              "/assets/img/login/copy.svg"
                            }
                            onClick={() =>
                              copyToClipboard(
                                auth.accounts
                              )
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <Container>
          <div className="video-view-body-sec">
            <div className="video-view-card">
              <div className="video-view-info">
                <h3>Lorem Ipsum</h3>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt.{" "}
                </p>
              </div>
              <div className="video-view-player-sec">
                <ReactPlayer
                  url={video.data.video?.video_url}
                  width="100%"
                  height="100%"
                  className="metaview-player"
                  muted={false}
                  autoPlay={true}
                  playing={video.loading ? false : true}
                  loop={false}
                  controls={false}
                  onEnded={handleVideoEnd}
                  onReady={handleVideoPlay}
                  onBuffer={handleVideoBuffer}
                />
                {playerStatus.buffering && (
                  <div className="spinner">
                    <Spinner animation="border" role="status" />
                  </div>
                )}
              </div>
              {/* <div className="upload-video-button-wrapper ">
                <Link className="upload-video-btn link" to="/video-upload">
                  Upload Video
                </Link>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.users.profile,
  video: state.video.video,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewIndex);
