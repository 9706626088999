import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsSuccess,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsFailure,
} from "../actions/UserAction";

import api from "../../Environment";
import {
  FETCH_USER_DETAILS_START,
  UPDATE_USER_DETAILS_START,
  LOGIN_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "is_subscription_enabled",
        response.data.data.is_subscription_enabled
      );
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateUserDetailsAPI(action) {
  try {
    // const userData = yield select((state) => state.users.profileInputData.data);
    const response = yield api.postMethod("update_profile", action.data);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("is_model", response.data.data.is_model);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      yield call(getErrorNotificationMessage, response.data.message);
      // setTimeout(() => {
      //   window.location.assign("/account/profile");
      // }, 1000);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(updateUserDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userLoginAPI() {
  try {
    const userData = yield select((state) => state.users.loginInputData.data);

    const response = yield api.postMethod("login", userData);
    yield put(userLoginSuccess(response.data));
    if (response.data.success) {
      if (response.data.code == 1001)
        window.location.assign("/register-verify");
      else {
        localStorage.setItem("wallet_address", userData.wallet_address);
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        localStorage.setItem(
          "is_document_verified",
          response.data.data.is_document_verified
        );
      }
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
  ]);
}
