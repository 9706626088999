import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

const ProfileLayout = (props) => {
  return (
    <>
      <ToastContainer limit={3} />
      {React.cloneElement(props.children)}
    </>
  );
};

export default ProfileLayout;
