import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Image } from "react-bootstrap";
import "./VideoUpload.css";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import { saveVideoStart } from "../store/actions/VideosAction";
import { connect } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";

//Declare IPFS
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const VideoUploadeIndex = (props) => {
  const { videoSave, dispatch } = props;

  const [videoData, setVideoData] = useState({
    loading: false,
    title: "",
    description: "",
    files: null,
    preview: null,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        setVideoData({
          ...videoData,
          preview: reader.result,
          files: acceptedFiles[0],
        });
      };

      if (acceptedFiles.length >= 0) {
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
    [videoData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/mp4 , video/avi , video/mkv",
    maxFiles: 1,
  });

  const handleInputChange = (event) => {
    setVideoData({
      ...videoData,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpload = (event) => {
    event.preventDefault();
    if (videoData.files == null) {
      getErrorNotificationMessage("video not selected");
    } else {
      setVideoData({
        ...videoData,
        loading: true,
      });

      ipfs.add(videoData.files, async (error, result) => {
        console.log("Ipfs result", result);
        if (error) {
          console.error(error);
          return;
        }
        setVideoData({
          ...videoData,
          loading: false,
        });
        dispatch(
          saveVideoStart({
            video_file: videoData.files,
            title: videoData.title,
            description: videoData.description,
            ipfs_url: `https://ipfs.io/ipfs/${result[0].hash}`,
          })
        );
      });
    }
  };

  return (
    <>
      <div className="video-upload-sec">
        <div className="video-upload-box">
          <div className="video-upload-card-left">
            <div className="video-upload-card-header">
              <h2>
                We are
                <span>
                  <Image
                    className="video-upload-logo"
                    src={
                      window.location.origin +
                      "/assets/img/login/meta-view-black.png"
                    }
                  />
                </span>
              </h2>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt.{" "}
              </p>
              <div className="video-upload-card-wrapper">
                <Form className="video-upload-form" onSubmit={handleUpload}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>File Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="File Name"
                      required
                      name="title"
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      className="height-auto"
                      placeholder="Enter the text"
                      required
                      name="description"
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Form.Group>
                  <div className="video-upload-btn-sec">
                    <Button
                      className="upload-video-btn"
                      type="submit"
                      disabled={videoData.loading || videoSave.buttonDisable}
                    >
                      {videoData.loading
                        ? "uploading file to ipfs..."
                        : videoSave.loading
                        ? videoSave.loadingButtonContent
                        : "Upload Video"}
                    </Button>
                    {(videoData.loading || videoSave.loading) || (
                      <Link to="/video-view" className="cancel-btn">
                        Cancel
                      </Link>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="video-upload-card-right">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="video-select-btn">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="#B7B7B7"
                    class="bi bi-upload"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                  </svg>
                </span>
                Select the video or drop one here
              </p>
            </div>
            <div className="video-upload-right-card-info">
              <div className="video-view-info">
                {videoData.files == null ? (
                  <h3>No File selected</h3>
                ) : (
                  <ReactPlayer
                    url={videoData.preview}
                    width="100%"
                    height="100%"
                    className="metaview-player"
                    playsinline={true}
                    muted={true}
                    autoPlay={true}
                    playing={false}
                    loop={false}
                    controls={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  videoSave: state.video.videoSave,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoUploadeIndex);
