import React , {useContext} from "react";
import { Link } from "react-router-dom"
import {Form, Button, Image, Modal} from "react-bootstrap";
import {authContext} from "../account/auth/AuthProvider"

const LoginModal = (props) => {

  const {loginConnectors , handleConnector , auth} = useContext(authContext)

  return (
    <>
      <Modal
        className="modal-dialog-center login-modal-sec"
        size="md"
        centered
        show={props.login}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Connect Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Start playing 
              <span> 
                <Image
                    className="login-modal-logo"
                    src={
                        window.location.origin + "/assets/img/login/meta-view-pink.png"
                    }
                />
              </span>
            </h5>
            <p>Sign in to start playing! Or if you're a new user,</p>
            <div className="login-wallet-box">
              <Image
                  className="metamask-logo"
                  src={
                      window.location.origin + "/assets/img/login/metamask-logo.png"
                  }
              />
              <Link to="#" className="signin-meta-link">Sign in with Metamask</Link>
              <Button className="signin-btn" onClick={() => handleConnector(loginConnectors.find(connectors => connectors.name === "MetaMask").connectorFunction)}>
                {auth.loading ? "Signing in..." : " Sign In"}
              </Button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default LoginModal;