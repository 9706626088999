import {
  FETCH_VIDEO_START,
  FETCH_VIDEO_SUCCESS,
  FETCH_VIDEO_FAILURE,
  SAVE_VIDEO_START,
  SAVE_VIDEO_SUCCESS,
  SAVE_VIDEO_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  video: {
    loading: true,
    data: {},
    error: null,
  },
  videoSave: {
    loading: false,
    loadingButtonContent: null,
    buttonDisable: false,
    data: {},
    error: null,
  },
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_START:
      return {
        ...state,
        video: {
          ...state.video,
          loading: true,
        },
      };

    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        video: {
          ...state.video,
          loading: false,
          data: action.data,
        },
      };

    case FETCH_VIDEO_FAILURE:
      return {
        ...state,
        video: {
          ...state.video,
          loading: true,
          data: {},
          error: action.data,
        },
      };

    case SAVE_VIDEO_START:
      return {
        ...state,
        videoSave: {
          ...state.video,
          loading: true,
          loadingButtonContent : "uploading...",
          buttonDisable : true
        },
      };

    case SAVE_VIDEO_SUCCESS:
      return {
        ...state,
        videoSave: {
          ...state.video,
          loading: false,
          data: action.data,
          loadingButtonContent : null,
          buttonDisable : false
        },
      };

    case SAVE_VIDEO_FAILURE:
      return {
        ...state,
        videoSave: {
          ...state.video,
          loading: false,
          data: {},
          error: action.data,
          loadingButtonContent : null,
          buttonDisable : false
        },
      };

    default:
      return state;
  }
};

export default videoReducer;
