import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchVideosSuccess,
  fetchVideosFailure,
  saveVideoSuccess,
  saveVideoFailure,
} from "../actions/VideosAction";

import api from "../../Environment";
import { FETCH_VIDEO_START, SAVE_VIDEO_START } from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";

import { Redirect } from "react-router-dom";

function* getVideoAPI() {
  try {
    const response = yield api.postMethod("videos_view");

    if (response.data.success) {
      yield put(fetchVideosSuccess(response.data.data));
    } else {
      yield put(fetchVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchVideosFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* saveVideoAPI(action) {
  try {
    const response = yield api.postMethod("videos_save" , action.data);

    if (response.data.success) {
      yield put(saveVideoSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.reload();
    } else {
      yield put(saveVideoFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
      <Redirect to="/thank-you" />;
    }
  } catch (error) {
    yield put(saveVideoFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_VIDEO_START, getVideoAPI),
    yield takeLatest(SAVE_VIDEO_START, saveVideoAPI),
  ]);
}
