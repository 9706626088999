import React, { Component, useEffect, useState, useContext } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translation/en.json";
import pt from "../translation/pt.json";
import { authContext } from "../account/auth/AuthProvider";
import NewLayout from "../Layouts/NewLayout";
import LoginIndex from "../Auth/LoginIndex";
import VideoViewIndex from "../VideoUpload/VideoViewIndex";
import VideoUploadeIndex from "../VideoUpload/VideoUploadeIndex";
import ThankYou from "../VideoUpload/ThankYou";

const history = createHistory();
const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>  authentication === true ? (
      <Redirect to={{ pathname: "/video-view", state: { from: props.location } }} />
    ) : (
      <Layout screenProps={ScreenProps} {...props} {...rest}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps} {...props} {...rest}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  const { auth } = useContext(authContext);

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    setAuthntication(
      userId && accessToken && userLoginStatus == "true" ? true : false
    );
    document.body.scrollTop = 0;
  });

  useEffect(() => {
    setAuthntication(auth.authStatus);
    document.body.classList.remove("noBodyOverflow");
  }, [auth.authStatus]);

  return (
    <>
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
          // sizes="16x16"
        />
      </Helmet>
      <Switch>
       
        <AppRoute
          path={"/"}
          component={LoginIndex}
          layout={NewLayout}
          exact
          authentication={authentication}
        />

        <PrivateRoute
          path={"/video-view"}
          component={VideoViewIndex}
          layout={NewLayout}
          authentication={authentication}
        />

        <AppRoute
          path={"/video-upload"}
          component={VideoUploadeIndex}
          layout={NewLayout}
        />

        <AppRoute
          path={"/thank-you"}
          component={ThankYou}
          layout={NewLayout}
        />

        <Route path="*" component={NotFoundIndex} />
      </Switch>
    </>
  );
};

export default App;
