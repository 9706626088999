import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import "./Auth.css";
import LoginModal from "./LoginModal";

const LoginIndex = (props) => {

    const [login, setLogin] = useState(true);

    const closeLoginModal = () => {
      setLogin(false);
    };

    return (
        <>
            <div className="login-sec">
            </div>
            {login && (
                <LoginModal login={login} closeLoginModal={closeLoginModal} />
            )}
            
        </>
    );
};

export default LoginIndex;
